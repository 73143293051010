import { AuthService } from './../../../views/sessions/signin/auth-service.service';
import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

 
@Injectable()
export class AuthGuard implements CanActivate {
  static isAuthenticated: boolean;
  public authToken;
  private isAuthenticated = false; // Set this value dynamically
   

  constructor(
    private router: Router        
   ) {}
  
  canActivate(
       route: ActivatedRouteSnapshot,
       state: RouterStateSnapshot):Observable<boolean>|boolean {
    
      if (localStorage.getItem('user')) {      
          //console.log(localStorage.getItem('user'));
          this.isAuthenticated = true;
          //this.router.navigate(['/dashboard']);
        return true      
      }
    this.router.navigate(['/sessions/signin']);
    return false;
  }
  
  ngOnInit() {
     
  }
}